const React = require('react');
const { useContext } = require('react');
const { arrayOf, shape } = require('prop-types');
const classnames = require('classnames');

const MediaAction = require('../../../media/media-action');
const HSHighlightedFeaturesList = require('../hs-highlighted-features-list');
const StaticPropsContext = require('../../../context/static-props');

const namespace = 'ui-vpp-highlighted-specs__features';

const HSHighlightedFeatures = ({ features, action }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const isDesktop = deviceType === 'desktop';

  const onClickHandler = e => {
    e.preventDefault();
    const seeMoreButtonSpecs = document.querySelector(
      '.ui-vpp-highlighted-specs__striped-collapsed__action .ui-pdp-collapsable__action',
    );
    const spectScroll =
      document.querySelector('.ui-pdp-container__row--highlighted-specs-title') ??
      document.querySelector('.ui-pdp-container__row--technical-specifications');

    const specs = spectScroll;

    if (seeMoreButtonSpecs) {
      seeMoreButtonSpecs.click();
    }

    if (specs) {
      specs.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={classnames(namespace, {
        'ui-pdp-with--separator': !isDesktop,
      })}
    >
      <HSHighlightedFeaturesList features={features} />
      {action && isDesktop && (
        <MediaAction
          target="#"
          className={`${namespace}-action`}
          title={action.label.text}
          label={action.label}
          track={action.track}
          data-testid="highlighted-features-action"
          onClick={onClickHandler}
        />
      )}
    </div>
  );
};

HSHighlightedFeatures.propTypes = {
  action: shape({}),
  features: arrayOf(shape({}).isRequired).isRequired,
};

HSHighlightedFeatures.defaultProps = {
  action: null,
};

module.exports = HSHighlightedFeatures;
