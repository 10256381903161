const React = require('react');
const { arrayOf, shape, string } = require('prop-types');
const classnames = require('classnames');
const { generateKeyToComponent } = require('../../../../utils/validators');

const namespace = 'ui-vpp-highlighted-specs__features-list';

const HSHighlightedFeaturesList = ({ features }) => (
  <ul className={namespace}>
    {features.map(feature => (
      <li
        key={generateKeyToComponent(feature)}
        className={classnames(
          `${namespace}-item`,
          `ui-pdp-color--${feature.color}`,
          `ui-pdp-size--${feature.font_size}`,
          `ui-pdp-family--${feature.font_family}`,
        )}
      >
        {feature.text}
      </li>
    ))}
  </ul>
);

HSHighlightedFeaturesList.propTypes = {
  features: arrayOf(
    shape({
      color: string.isRequired,
      font_family: string.isRequired,
      font_size: string.isRequired,
      text: string.isRequired,
    }).isRequired,
  ).isRequired,
};

module.exports = HSHighlightedFeaturesList;
